import React, { useEffect, useState } from "react";
import "./newpassword.css";
import UseGeneral from "../../hooks/useGeneral";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useNavigate, useLocation } from "react-router-dom";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";

const NewPassword = () => {
  const [showPass, setShowPass] = useState(false);
  const [showConfPass, setShowConfPass] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const location = useLocation();
  const [passwordError, setPasswordError] = useState("");
  const [passError, setPassError] = useState("");
  useEffect(() => {
    document.title =
      language == "ar" ? "الصيداوي | كلمة المرور الجديدة   " : "Al-Seedawi | New Password";
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (password.length < 8) {
      setPassError(
        language === "ar"
          ? "يجب الا تقل كبمة المرور عن 8"
          : "password must be at least 8"
      );
      return;
    }

    if (password !== confirmPassword) {
      setPasswordError(
        language === "ar" ? "كلمات السر غير متطابقة" : "Passwords do not match"
      );
      return;
    }

    setLoading(true);
    Axios({
      url: BASE_URL + "user/update_password",
      method: "POST",
      data: { phone: location.state?.phone, password },
    })
      .then((res) => {
        
        if (res.message === "Sission Ended Login Again") {
          toast.error(res.message);
        }
        if (res.status === "success") {
          toast.success(res.message);
          const paramsString = window.location.search;
          const searchParams = new URLSearchParams(paramsString);
          if (searchParams.get("route")) {
           
        
          navigate("/changesuccess?route=" + searchParams.get("route"), {});
  
          } else {
            navigate("/changesuccess", {});

          }
        }
        if(res.status=='faild'){
          toast.error(res.message)
        }
      })
      .catch((e) => {
        
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="newpass_page">
      <div className="newpass_content">
        <div className="left">
          <h5>
            {language === "ar"
              ? "إنشاء كلمة مرور جديدة"
              : "Create New Password"}
          </h5>
          {language === "ar" ? (
            <p>
              يجب أن تكون كلمة المرور الجديدة فريدة وغير متشابهة مع الكلمات
              المستخدمة سابقًا.
            </p>
          ) : (
            <p>
              New Password Keyword Must Be Uniq And Not Similar With Old Keyword
            </p>
          )}
          <form action="">
            <div>
              <input
                className="form-control"
                type={!showPass ? "password" : "text"}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={
                  language === "ar"
                    ? "أدخل كلمة مرور جديده"
                    : "Enter New Password"
                }
              />
              {showPass ? (
                <IoEyeOutline
                  className={language=='en'?'right':''}
                  onClick={() => {
                    setShowPass(!showPass);
                  }}
                />
              ) : (
                <IoEyeOffOutline
                className={language=='en'?'right':''}
                  onClick={() => {
                    setShowPass(!showPass);
                  }}
                />
              )}
            </div>
            {passError && (
              <p className="password-error" style={{ color: "rgb(255,0,0)" }}>
                {passError}
              </p>
            )}
            <div>
              <input
                className="form-control"
                type={!showConfPass ? "password" : "text"}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setPasswordError(""); // Clear password error when typing in the confirm password field
                }}
                placeholder={
                  language === "ar" ? "إعادة الإدخال" : "Enter It Again"
                }
              />
              {showConfPass ? (
                <IoEyeOutline
                className={language=='en'?'right':''}
                  onClick={() => {
                    setShowConfPass(!showConfPass);
                  }}
                />
              ) : (
                <IoEyeOffOutline
                className={language=='en'?'right':''}
                  onClick={() => {
                    setShowConfPass(!showConfPass);
                  }}
                />
              )}
            </div>
            {passwordError && (
              <p className="password-error" style={{ color: "rgb(255,0,0)" }}>
                {passwordError}
              </p>
            )}
            {
              loading?
              <div style={{textAlign:'center'}}>
                <ThreeDots color="red"/>
              </div>
              :
              <button onClick={handleSubmit}>
              {language === "ar" ? "تغيير" : "change"}
            </button>
            }
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
