import React, { useEffect, useState } from 'react';
import './forget.css';
import UseGeneral from "../../hooks/useGeneral";
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import axios from 'axios';
import { BASE_URL } from '../../Axios/base_url';
import { ThreeDots } from 'react-loader-spinner';
const Forget = () => {
  const navigate = useNavigate();
  const { language } = UseGeneral();
  const [loading,setLoading]=useState(false)
  const [phoneNumber, setPhoneNumber] = useState('');
  const handleSendVerf=()=>{
    if(phoneNumber==''){
      toast.error(language=='ar'?'أدخل رقم الهاتف':'Enter Phone Number')
      return;
    }
    setLoading(true)
    const data_send={
      phone:phoneNumber
    }
    axios.post(BASE_URL+'user/send_verf_code',data_send,{
      headers:{
        lang:language
      }
    })
    .then((res)=>{
      
      if(res.data.status=='faild'){
        toast.error(res.data.message);
      }
      
      if(res.data.status=='success'){
        const paramsString = window.location.search;
        const searchParams = new URLSearchParams(paramsString);
        if (searchParams.get("route")) {
         
        navigate('/confnumber?route=' + searchParams.get("route"),{state:{phone:phoneNumber}});

        } else {
          navigate('/confnumber',{state:{phone:phoneNumber}});
        }
        toast.success(res.data.message)
      }
    }).catch(e=>e)
    .finally(()=>{
      setLoading(false)
    })
  }
  useEffect(() => {
    document.title =
      language == "ar" ? "الصيداوي | هل نسيت كلمة المرور   " : "Al-Seedawi | Forget Password";
  }, []);
  return (
    <div className="forget_page">
      <div className="forget_content">
        <div className="left">
          <h4>
            {language == 'ar' ? 'نسيت كلمة المرور؟' : 'Forgot your password'}
          </h4>
          <p>
            {language == 'ar'
              ? 'لا داعي للقلق! يرجى إدخال رقم الهاتف الصحيح حتي يتم إرسال رمز التحقق له'
              : 'Do not worry! Please enter the correct phone number so that a verification code will be sent to it'}
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSendVerf()
            }}
            action=""
          >
            <input
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              className="form-control"
              type="text"
              placeholder={
                language == 'ar' ? 'أدخل رقم الهاتف' : 'Enter Your Phone'
              }
            />
              {
              loading?
              (
                <ThreeDots color='red'/>
              )
              :
              (
                <button
              onClick={() => {
                let firstSec = phoneNumber.slice(0, 3);
                let secSec = phoneNumber.slice(5, 8);
              }}
            >
              {language == 'ar' ? 'إرسال الرمز' : "Resend"}
            </button>
              )
            }
          </form>
        </div>
      </div>
    </div>
  );
};

export default Forget;
