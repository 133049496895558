import React, { useEffect, useState } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import toast from "react-hot-toast";
import { Spinner } from "react-bootstrap";
import PopUp from "../popup";
const Checkoutcartdetails = ({
  totalPrice,
  totalQuantity,
  language,
  cart,
  total_check,
  getUserData,
  getMe,
  siteInfoData,
}) => {
  const navigate = useNavigate();
  const [checed, setCheked] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoLoading, setPromoLoading] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);

  const handleMakeDiscount = () => {
    setPromoLoading(true);
    const data_send = {
      code: promoCode,
    };
    Axios({
      url: BASE_URL + "coupons/use_coupon",
      method: "post",
      data: data_send,
    })
      .then((res) => {
        if (res.status == "success") {
          toast.success(res.message);
          // getUserData()
          getMe();
        } else {
          toast.error(res.message);
        }
      })
      .finally(() => {
        setPromoLoading(false);
      });
  };

  return (
    <div className="checkoutcartdetails_parent">
      <div className="checkoutcartdetails">
        <p className="total_price">
          <span>{language == "ar" ? "إجمالى المنتجات" : "Total Products"}</span>
          <span>
            {" "}
            {total_check
              ? total_check?.toFixed(3)
              : 0}{" "}
            {language == "ar" ? "د.ك" : "KWD"}
          </span>
        </p>
        <p className="delivery_price">
          <span>{language == "ar" ? "التوصيل" : "Delivery"}</span>
          <span>
            {" "}
            {(total_check * 1 > 10 ? 0 : 1.5)} {language == "ar" ? "د.ك" : "KWD"}
          </span>
        </p>
        <div className="promo_div">
          <p>{language == "ar" ? "البروموكود" : "Promocode"}</p>
          <input
            onChange={(e) => {
              setPromoCode(e.target.value);
            }}
            type="text"
            className="copounDesign"
            placeholder={
              language == "ar"
                ? "أضف البروموكود الخاص بك"
                : "Add Your Promo Code"
            }
          />
        </div>
        {promoLoading ? (
          <Spinner />
        ) : (
          <button
            onClick={() => {
              handleMakeDiscount();
            }}
            className="btn btn-danger mb-4"
          >
            {language == "ar" ? "تفعيل الكوبون" : "Activate Copoun"}
          </button>
        )}
        <div className="tot_line">
          <img style={{width:"100%"}} src={require("../../assets/line.png")} alt="" />
          <div>
            <h3>{language == "ar" ? "الإجمالى" : "Total"}: </h3>
            <h6>
              {language == "ar" ? (
                <span>
                  {(
                    total_check * 1 > 10
                      ? total_check * 1 + 0
                      : total_check * 1 + 1.5
                  )?.toFixed(3)
                    ? (total_check * 1 > 10
                        ? total_check * 1 + 0
                        : total_check * 1 + 1.5
                      )?.toFixed(3)
                    : 0}{" "}
                  د.ك
                </span>
              ) : (
                <span>
                  {(
                    total_check * 1 > 10
                      ? (total_check * 1 + 0)
                      : total_check * 1 + 1.5
                  )?.toFixed(3)
                    ? (total_check * 1 > 10
                        ? total_check * 1 + 0
                        : total_check * 1 + 1.5
                      )?.toFixed(3)
                    : 0}{" "}
                  KWD
                </span>
              )}
            </h6>
          </div>
        </div>
        <button
          className="cartPay"
          onClick={() => {
            if (!localStorage?.getItem("GreenTreesToken")) {
          window.location.href = "/sign?route=Cart"

              toast.error(language == 'ar' ? 'سجل الدخول أولا' : 'LogIn First');
              return;
            }
            navigate("/pay-details");
          }}
        >
          {language == "ar" ? "الدفع" : "Pay"}
        </button>
      </div>
      <div className="conf_pay_div">
        {/* <div
          onClick={() => {
            setCheked(!checed);
          }}
          className="input_check"
        >
          <div
            className={checed ? "input_check_child act" : "input_check_child"}
          ></div>
        </div> */}
        {/* <h4>
          {language == "ar"
            ? "أوافق على شروط الخدمه وألتزم بها دون قيد أو شرط"
            : "I agree to the terms of service and abide by them without restriction or condition"}{" "}
          <span  onClick={()=> setPrivacyModal(true)}>({language == "ar" ? "قراءه" : "Read"})</span>
        </h4> */}
      </div>

      <PopUp
        open={privacyModal}
        title={language == "ar" ? "سياسة الخصوصية" : "Privacy Policy"}
        setOpen={setPrivacyModal}
        children={
          <>
            <div className="fs-4 fw-bolder mx-3">
              {language == 'ar'
                ? siteInfoData?.privacy_ar
                : siteInfoData?.privacy_en}
            </div>
          </>
        }
      />
    </div>
  );
};

export default Checkoutcartdetails;
